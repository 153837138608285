import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { enableProdMode, importProvidersFrom, inject } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { EffectsModule, provideEffects } from '@ngrx/effects';
import { provideStore, StoreModule } from '@ngrx/store';
import {
  provideStoreDevtools,
  StoreDevtoolsModule,
} from '@ngrx/store-devtools';
import {
  APP_STARTUP,
  ISHTAR365_APP_IDENTIFIER,
  ISHTAR365_CLIENTID,
  ISHTAR365_INSTANCE,
  ISHTAR365_URL,
  MsalModule,
  ISHTAR365_LAST_ROUTE_KEY,
  ISHTAR365_INITIAL_ROUTE,
  microsoftAuthenticationInterceptor,
  ISHTAR365_FUNCTIONS_URL,
  ISHTAR365_FUNCTIONS_SCOPE,
} from 'processdelight-angular-components';
import { AppComponent, appRoutes } from './app/app.component';
import { StartUpService } from './app/services/startup.service';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    { provide: ISHTAR365_CLIENTID, useValue: environment.clientId },
    { provide: ISHTAR365_INSTANCE, useValue: environment.instance },
    { provide: ISHTAR365_URL, useValue: environment.ishtar365 },
    { provide: ISHTAR365_APP_IDENTIFIER, useFactory: () => location.origin },
    { provide: ISHTAR365_FUNCTIONS_URL, useValue: environment.ishtarFunctions },
    {
      provide: ISHTAR365_FUNCTIONS_SCOPE,
      useValue: environment.ishtarFunctionsScope,
    },
    {
      provide: APP_STARTUP,
      useFactory: () => {
        const service = inject(StartUpService);
        return () => service.boot();
      },
    },
    {
      provide: ISHTAR365_LAST_ROUTE_KEY,
      useValue: 'Ishtar.CRM-LastRouteKey',
    },
    { provide: ISHTAR365_INITIAL_ROUTE, useValue: ['clients'] },
    provideRouter(appRoutes),
    provideStore({}),
    provideEffects([]),
    provideStoreDevtools({ logOnly: false }),
    provideHttpClient(
      withInterceptors([microsoftAuthenticationInterceptor('')])
    ),
    importProvidersFrom(
      StoreModule.forRoot({}),
      EffectsModule.forRoot([]),
      StoreDevtoolsModule.instrument({ logOnly: environment.production }),
      MsalModule
    ),
    provideAnimations(),
  ],
});
