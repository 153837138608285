import { Action, createReducer, on } from '@ngrx/store';
import { AppState } from 'src/app/shared/store/app.reducer';
import { Contact } from '../../models/contact';
import {
  getContactsResolved,
  updateContactResolved,
  addContactResolved,
  removeContactResolved,
} from './contact.actions';
export const featureSlice = 'contact';

export interface State {
  contacts: Contact[];
  ishtarClientId?: string;
  ishtarContactId?: string;
}
const defaultState: State = {
  contacts: [],
  ishtarClientId: undefined,
  ishtarContactId: undefined,
};

export function Reducer(state: State | undefined, action: Action) {
  return contactReducer(state, action);
}

export const initialState: State = defaultState;

export const contactReducer = createReducer(
  initialState,
  on(getContactsResolved, (state, { contacts }) => ({
    ...state,
    contacts: [
      ...state.contacts.filter(
        (c) => !contacts.some((c1) => c.ishtarContactId == c1.ishtarContactId)
      ),
      ...contacts,
    ],
  })),
  on(removeContactResolved, (state, { ishtarContactId }) => ({
    ...state,
    contacts: state.contacts.filter(
      (c) => c.ishtarContactId != ishtarContactId
    ),
  })),
  on(updateContactResolved, (state, { contact }) => ({
    ...state,
    contacts: state.contacts.map((c) =>
      c.ishtarContactId == contact.ishtarContactId ? contact : c
    ),
  })),
  on(addContactResolved, (state, { contact }) => ({
    ...state,
    contacts: state.contacts.concat([contact]),
  }))
);

export const contactState = (state: AppState) => state.coreFeature.contact;
