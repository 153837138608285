import { createAction, props } from '@ngrx/store';
import { FormFieldConfiguration } from 'processdelight-angular-components';
import { CreateFieldConfigurationsRequest } from 'processdelight-angular-components/lib/custom-control/models/create-field-configurations-request';

export const getFieldConfigurations = createAction(
  '[FieldConfigurations] Get field configurations',
  props<{
    formId: string;
    recordId?: string;
    callback?: (fieldConfigurations: FormFieldConfiguration[]) => void;
  }>()
);

export const getFieldConfigurationsResolved = createAction(
  '[FieldConfiguration] Get data properties resolved',
  props<{
    result: FormFieldConfiguration[];
  }>()
);

export const addFieldConfigurations = createAction(
  '[FieldConfiguration] Add FieldConfigurations',
  props<{
    request: CreateFieldConfigurationsRequest;
    callback?: (fieldConfigurations: FormFieldConfiguration[]) => void;
    error?: () => void;
  }>()
);

export const addFieldConfigurationsResolved = createAction(
  '[FieldConfiguration] Add FieldConfigurations resolved',
  props<{ addedFieldConfigurations: FormFieldConfiguration[] }>()
);
