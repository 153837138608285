import { GroupUser } from './groupuser';

export class Client {
  ishtarClientId?: string;
  clientName!: string;
  address?: string;
  city?: string;
  clientNumber?: string;
  clientReference?: string;
  country?: { id?: string; name?: string };
  email?: string;
  phone?: string;
  postalCode?: string;
  vAT?: string;
  website?: string;
  customRates?: boolean;
  teamId?: string;
  accountManager?: GroupUser;
  phoneCountry?: { id?: string; name?: string };
  logo?: string

  constructor(obj: Partial<Client>) {
    Object.assign(this, obj);
  }
}
