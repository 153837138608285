import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap, tap, mergeMap } from 'rxjs/operators';
import { IshtarCRMService } from '../../services/ishtarcrm.service';
import {
  addClient,
  getClients,
  getClientsResolved,
  addClientResolved,
  updateClientResolved,
  updateClient,
  removeClient,
  removeClientResolved,
  getClient,
  getClientResolved,
} from './client.actions';

@Injectable({ providedIn: 'root' })
export class ClientEffects {
  constructor(
    private actions$: Actions,
    private ishtar365ishtarCRMService: IshtarCRMService
  ) {}
  getClients = createEffect(() =>
    this.actions$.pipe(
      ofType(getClients),
      mergeMap(
        ({
          newPageSize,
          sortedColumn,
          sortDirection,
          filters,
          newPagingCookie,
          callback,
        }) =>
          this.ishtar365ishtarCRMService
            .getClients(
              newPageSize,
              sortedColumn,
              sortDirection,
              filters,
              newPagingCookie
            )
            .pipe(
              switchMap(({ result, pagingCookie, totalRecordCount }) =>
                of(
                  getClientsResolved({
                    result,
                    pagingCookie,
                    totalRecordCount,
                    resetPaging: !newPagingCookie,
                  })
                )
              ),
              tap(() => (callback ? callback() : undefined)),
              catchError((e) => [])
            )
      )
    )
  );

  getClient = createEffect(() =>
    this.actions$.pipe(
      ofType(getClient),
      mergeMap(({ ishtarClientId, callback }) =>
        this.ishtar365ishtarCRMService.getClient(ishtarClientId).pipe(
          tap((client) => (callback ? callback(client) : undefined)),
          switchMap((client) => of(getClientResolved({ client }))),
          catchError((e) => [])
        )
      )
    )
  );

  addClient = createEffect(() =>
    this.actions$.pipe(
      ofType(addClient),
      switchMap(({ client, createTeam, callback }) =>
        this.ishtar365ishtarCRMService.addClient(client, createTeam).pipe(
          switchMap((client) => of(addClientResolved({ client }))),
          tap((result) => callback?.(result.client)),
          catchError((e) => [])
        )
      )
    )
  );

  updateClient = createEffect(() =>
    this.actions$.pipe(
      ofType(updateClient),
      switchMap(({ client, callback }) =>
        this.ishtar365ishtarCRMService.updateClient(client).pipe(
          switchMap((client) =>
            of(updateClientResolved({ client: client[0] }))
          ),
          tap(() => callback?.()),
          catchError((e) => [])
        )
      )
    )
  );

  removeClient = createEffect(() =>
    this.actions$.pipe(
      ofType(removeClient),
      switchMap(({ ishtarClientId, callback }) =>
        this.ishtar365ishtarCRMService.removeClient(ishtarClientId).pipe(
          switchMap((data) =>
            of(removeClientResolved({ ishtarClientId: data[0] }))
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );
}
