import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap, tap } from 'rxjs';
import { IshtarCRMService } from 'src/app/services/ishtarcrm.service';
import {
  getDataProperties,
  getDataPropertiesResolved,
} from './data-property.action';

@Injectable({ providedIn: 'root' })
export class DataPropertyEffects {
  constructor(
    private functionsService: IshtarCRMService,
    private actions$: Actions
  ) {}

  getDataProperties = createEffect(() =>
    this.actions$.pipe(
      ofType(getDataProperties),
      switchMap(({ callback }) =>
        this.functionsService.getDataProperties().pipe(
          switchMap((result) =>
            of(
              getDataPropertiesResolved({
                result,
              })
            )
          ),
          tap((result) => callback?.(result.result))
        )
      )
    )
  );
}
