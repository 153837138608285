import { Lookup } from 'processdelight-angular-components';
import { AADUser } from './aadUser';
import { Group } from './group';
export class GroupUser {
  user?: AADUser;
  group?: Group;
  get displayName() {
    return this.user ? this.user?.displayName : this.group?.displayName;
  }

  constructor(obj: Partial<GroupUser>) {
    Object.assign(this, obj);
  }

  toLookup(): Lookup {
    return new Lookup({ id: this.user?.id ?? this.group?.groupId, name: this.displayName });
  }
}
