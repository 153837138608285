import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApplicationData,
  ControlConfiguration,
  Filter,
  FormFieldConfiguration,
  Lookup,
  MicrosoftAuthenticationService,
  PropertyType,
} from 'processdelight-angular-components';
import { CreateFieldConfigurationsRequest } from 'processdelight-angular-components/lib/custom-control/models/create-field-configurations-request';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AADUser } from '../models/aadUser';
import { Client } from '../models/client';
import { Contact } from '../models/contact';
import { Country } from '../models/country';
import { GroupUser } from '../models/groupuser';
import { RecordDetail } from '../models/record-detail';
import { UserLicenseInfo } from '../models/userlicenseinfo';
import { varlicense$ } from './startup.service';

@Injectable({
  providedIn: 'root',
})
export class IshtarCRMService {
  apiBase = `${environment.ishtarFunctions}/api`;
  constructor(
    private httpClient: HttpClient,
    private msal: MicrosoftAuthenticationService
  ) {}

  private createApiEndpointUrl(path: string) {
    const url = new URL(`${this.apiBase}/${path}`);
    if (environment.ishtarFunctionsKey.trim() !== '')
      url.searchParams.append('code', environment.ishtarFunctionsKey);
    return url.toString();
  }

  filterQuery(filters: Filter[]) {
    if (filters.length > 0) {
      const filterString = '&expand=Country&select=*,Country&filter=';
      return (
        filterString +
        filters
          .map((filter) => `('${filter.value}' in ${filter.columnName})`)
          .join(' and ')
      );
    } else {
      return '';
    }
  }
  orderByQuery(column: string, direction: string) {
    if (!column || !direction) return '';
    else return `&orderBy=${column} ${direction.toUpperCase()}`;
  }
  getLicense() {
    return this.httpClient.get<UserLicenseInfo>(
      this.createApiEndpointUrl(
        `license/${this.msal.tenantId}/${this.msal.email}`
      )
    );
  }

  getClients(
    newPageSize: number,
    sortedColumn: string,
    sortDirection: string,
    filters: Filter[],
    newPagingCookie?: string
  ) {
    return this.httpClient
      .get<{
        result: Client[];
        pagingCookie: string;
        totalRecordCount: number;
      }>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarClient?${
            newPagingCookie ? `pagingCookie=${newPagingCookie}&` : ''
          }doPaging=true&retrieveTotalRecordCount=true&top=${newPageSize}${this.orderByQuery(
            sortedColumn,
            sortDirection
          )}${this.filterQuery(filters)}`
        )
      )
      .pipe(
        map(({ result, pagingCookie, totalRecordCount }) => ({
          result: result.map((c) => new Client(this.camelcaseKeys(c))),
          pagingCookie,
          totalRecordCount,
        }))
      );
  }

  getClient(ishtarClientId: string) {
    return this.httpClient
      .get<Client[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarClient?filter=IshtarClientId eq ${ishtarClientId} `
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(map(([client]) => new Client(this.camelcaseKeys(client))));
  }

  removeClient(id: string) {
    return this.httpClient.delete<string[]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${
          varlicense$.value!.dataverseEnvironmentUrl
        }/IshtarClient/records`
      ),
      {
        body: [id],
        headers: {
          ImpersonationUserAADId: varlicense$.value!.microsoftId,
        },
      }
    );
  }

  updateClient(client: Client) {
    const updatedClient = this.capitalizeKeys(
      client,
      'country',
      'phoneCountry'
    );
    return this.httpClient
      .patch<Client[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarClient/records`
        ),
        [updatedClient],
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((client) => client.map((u) => new Client(this.camelcaseKeys(u))))
      );
  }

  addClient(client: Client, createTeam?: boolean) {
    const updatedClient = this.capitalizeKeys(
      client,
      'country',
      'phoneCountry'
    );
    return this.httpClient
      .post<Client>(
        this.createApiEndpointUrl(
          `ishtar/crm/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/clients?createTeam=${createTeam ?? false}`
        ),
        updatedClient,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(map((client) => new Client(this.camelcaseKeys(client))));
  }

  getContacts(ishtarClientId: string) {
    return this.httpClient
      .get<Contact[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarContact?expand=Client&select=*,Client&filter=Client/IshtarClientId eq ${ishtarClientId}`
        )
      )
      .pipe(
        map((contacts) =>
          contacts.map((c) => new Contact(this.camelcaseKeys(c)))
        )
      );
  }

  removeContact(ishtarContactId: string) {
    return this.httpClient.delete<string[]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${
          varlicense$.value!.dataverseEnvironmentUrl
        }/IshtarContact/records`
      ),
      {
        body: [ishtarContactId],
        headers: {
          ImpersonationUserAADId: varlicense$.value!.microsoftId,
        },
      }
    );
  }

  addContact(contact: Contact) {
    const newContact = this.capitalizeKeys(
      contact,
      'client',
      'language',
      'phoneCountry'
    );
    return this.httpClient
      .post<Contact[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarContact/records`
        ),
        [newContact],
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((contact) => contact.map((c) => new Contact(this.camelcaseKeys(c))))
      );
  }

  updateContact(contact: Contact) {
    const updatedContact = this.capitalizeKeys(
      contact,
      'client',
      'language',
      'phoneCountry'
    );
    return this.httpClient
      .patch<Contact[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarContact/records`
        ),
        [updatedContact],
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((contact) => contact.map((c) => new Contact(this.camelcaseKeys(c))))
      );
  }

  getCountries() {
    return this.httpClient.get<Country[]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${
          varlicense$.value!.dataverseEnvironmentUrl
        }/IshtarCountry`
      )
    );
  }

  getLanguages() {
    return this.httpClient.get<any[]>(
      this.createApiEndpointUrl(
        `dataverse/${this.msal.tenantId}/${
          varlicense$.value!.dataverseEnvironmentUrl
        }/IshtarLanguage`
      )
    );
  }

  getTranslations() {
    return this.httpClient.get<any>(
      this.createApiEndpointUrl(
        `ishtarapps/translations?lang=${varlicense$.value?.language}`
      )
    );
  }

  getUsers() {
    return this.httpClient
      .get<GroupUser[]>(
        this.createApiEndpointUrl(`users/${this.msal.tenantId}/Ishtar.CRM`)
      )
      .pipe(
        map((user) =>
          user.map(
            (u) =>
              new GroupUser({
                user: new AADUser(this.camelcaseKeys((u as any).User!)),
              })
          )
        )
      );
  }

  getGroups() {
    return this.httpClient
      .get<GroupUser[]>(
        this.createApiEndpointUrl(`groups/${this.msal.tenantId}/Ishtar.CRM`)
      )
      .pipe(
        map((group) => group.map((g) => new GroupUser(this.camelcaseKeys(g))))
      );
  }

  capitalizeKeys(obj: any, ...ignoredProperties: string[]): any {
    const ignoredPropertiesLower = ignoredProperties.map((p) =>
      p.toLowerCase()
    );

    if (Array.isArray(obj))
      return [...obj.map((o) => this.capitalizeKeys(o, ...ignoredProperties))];
    else if (obj instanceof Object)
      return Object.entries(obj).reduce(
        (acc, e) => ({
          ...acc,

          [e[0].charAt(0).toUpperCase() + e[0].slice(1)]:
            ignoredPropertiesLower.includes(e[0].toLowerCase())
              ? e[1]
              : this.capitalizeKeys(e[1], ...ignoredProperties),
        }),

        {}
      );
    else return obj;
  }
  camelcaseKeys(obj: any): any {
    if (Array.isArray(obj)) return [...obj.map((o) => this.camelcaseKeys(o))];
    else if (obj instanceof Object)
      return Object.entries(obj).reduce(
        (acc, e) => ({
          ...acc,

          [e[0].charAt(0).toLowerCase() + e[0].slice(1)]: this.camelcaseKeys(
            e[1]
          ),
        }),

        {}
      );
    else return obj;
  }

  getPropertyTypes() {
    return this.httpClient
      .get<PropertyType[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarPropertyType?select=*`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((propertyType) =>
          propertyType.map((p) => new PropertyType(this.camelcaseKeys(p)))
        )
      );
  }

  getApplicationForms() {
    const applicationName = 'IshtarCRM';

    return this.httpClient
      .get<Lookup[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarForm?expand=Application&select=*,Application&filter=Application/Name eq ${applicationName}`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((forms) => forms.map((f) => new Lookup(this.camelcaseKeys(f))))
      );
  }

  getCurrentApplication() {
    const applicationName = 'IshtarCRM';

    return this.httpClient
      .get<Lookup[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarApplication?filter=Name eq ${applicationName} `
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map(
          (applcations) =>
            applcations.map((p) => new Lookup(this.camelcaseKeys(p)))[0]
        )
      );
  }

  getPropertyRights() {
    return this.httpClient
      .get<Lookup[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarPropertyRight?select=*`
        ),
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((forms) => forms.map((f) => new Lookup(this.camelcaseKeys(f))))
      );
  }

  addFieldConfigurations(request: CreateFieldConfigurationsRequest) {
    return this.httpClient
      .post<FormFieldConfiguration[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/addFieldConfigurations`
        ),
        request
      )
      .pipe(
        map((fieldConfigurations) =>
          fieldConfigurations.map(
            (f) => new FormFieldConfiguration(this.camelcaseKeys(f))
          )
        )
      );
  }

  getFieldConfigurations(formId: string, recordId?: string) {
    const requestUrl = `dataverse/${this.msal.tenantId}/${
      varlicense$.value!.dataverseEnvironmentUrl
    }/getFieldConfigurations/${formId}/${recordId}`;

    return this.httpClient
      .get<FormFieldConfiguration[]>(this.createApiEndpointUrl(requestUrl))
      .pipe(
        map((fieldConfigurations) =>
          fieldConfigurations.map(
            (f) => new FormFieldConfiguration(this.camelcaseKeys(f))
          )
        )
      );
  }

  getDataProperties() {
    const applicationName = 'IshtarCRM';

    return this.httpClient
      .get<ControlConfiguration[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/getDataProperties/${applicationName}`
        )
      )
      .pipe(
        map((dataProperties) =>
          dataProperties.map(
            (dp) => new ControlConfiguration(this.camelcaseKeys(dp))
          )
        )
      );
  }

  addApplicationData(applicationData: ApplicationData[]) {
    const updatedApplicationData = applicationData.map((ad) => {
      ad.isValid = undefined;
      ad.value =
        typeof ad.value === 'object' ? JSON.stringify(ad.value) : ad.value;
      return this.capitalizeKeys(ad, 'dataType', 'DataObject');
    });

    return this.httpClient
      .post<ApplicationData[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarApplicationData/records`
        ),
        updatedApplicationData,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((applicationData) =>
          applicationData.map((c) => new ApplicationData(this.camelcaseKeys(c)))
        )
      );
  }

  updateApplicationData(applicationData: ApplicationData[]) {
    const updatedApplicationData: ApplicationData[] = applicationData.map(
      (ad) => {
        ad.isValid = undefined;
        ad.id = undefined!;
        ad.value =
          typeof ad.value === 'object' ? JSON.stringify(ad.value) : ad.value;
        return this.capitalizeKeys(ad, 'dataType', 'DataObject');
      }
    );

    return this.httpClient
      .patch<ApplicationData[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/IshtarApplicationData/records`
        ),
        updatedApplicationData,
        {
          headers: {
            ImpersonationUserAADId: varlicense$.value!.microsoftId,
          },
        }
      )
      .pipe(
        map((applicationData) =>
          applicationData.map((c) => new ApplicationData(this.camelcaseKeys(c)))
        )
      );
  }

  getRecordDetails(
    formId: string | undefined,
    ishtarClientId: string | undefined
  ): Observable<RecordDetail[]> {
    return this.httpClient
      .get<RecordDetail[]>(
        this.createApiEndpointUrl(
          `dataverse/${this.msal.tenantId}/${
            varlicense$.value!.dataverseEnvironmentUrl
          }/getRecordDetails/${formId}/${ishtarClientId}`
        )
      )
      .pipe(
        map((recordDetails) =>
          recordDetails.map((c) => new RecordDetail(this.camelcaseKeys(c)))
        )
      );
  }
}
