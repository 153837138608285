import { createAction, props } from '@ngrx/store';
import { Contact } from '../../models/contact';

export const getContacts = createAction(
  '[Contact] Get contacts',
  props<{
    ishtarClientId: string;
    callback?: () => void;
  }>()
);

export const getContactsResolved = createAction(
  '[Contact] Get contacts resolved',
  props<{
    contacts: Contact[];
  }>()
);

export const removeContact = createAction(
  '[Contact] Remove contact',
  props<{ ishtarContactId: string; callback?: () => void }>()
);

export const removeContactResolved = createAction(
  '[Contact] Remove contact resolved',
  props<{ ishtarContactId: string }>()
);

export const updateContact = createAction(
  '[Contact] Update contact',
  props<{ contact: Contact; callback?: () => void }>()
);

export const updateContactResolved = createAction(
  '[Contact] Update contact resolved',
  props<{ contact: Contact }>()
);

export const addContact = createAction(
  '[Contact] Add contact',
  props<{ contact: Contact; callback?: (result: Contact) => void }>()
);

export const addContactResolved = createAction(
  '[Contact] Add contact resolved',
  props<{ contact: Contact }>()
);
