import { Action, createReducer, on } from '@ngrx/store';
import { FormFieldConfiguration } from 'processdelight-angular-components';
import { AppState } from 'src/app/shared/store/app.reducer';
import { getFieldConfigurationsResolved } from './field-configuration.action';

export const featureSlice = 'fieldConfiguration';

export interface State {
  fieldConfigurations: FormFieldConfiguration[];
}

const defaultState: State = {
  fieldConfigurations: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return fieldConfigurationReducer(state, action);
}

export const initialState: State = defaultState;
export const fieldConfigurationReducer = createReducer(
  initialState,
  on(getFieldConfigurationsResolved, (state, { result }) => ({
    ...state,
    fieldConfigurations: [...result],
  }))
);

export const fieldConfigurationState = (state: AppState) =>
  state.coreFeature.fieldConfiguration;
