import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FormFieldConfiguration } from 'processdelight-angular-components';
import { catchError, of, switchMap, tap } from 'rxjs';
import { IshtarCRMService } from 'src/app/services/ishtarcrm.service';
import {
  addFieldConfigurations,
  addFieldConfigurationsResolved,
  getFieldConfigurations,
  getFieldConfigurationsResolved,
} from './field-configuration.action';

@Injectable({ providedIn: 'root' })
export class FieldConfigurationEffects {
  constructor(
    private functionsService: IshtarCRMService,
    private actions$: Actions
  ) {}

  getFieldConfigurations = createEffect(() =>
    this.actions$.pipe(
      ofType(getFieldConfigurations),
      switchMap(({ formId, recordId, callback }) =>
        this.functionsService.getFieldConfigurations(formId, recordId).pipe(
          switchMap((result) =>
            of(
              getFieldConfigurationsResolved({
                result,
              })
            )
          ),
          tap((result) => callback?.(result.result))
        )
      )
    )
  );

  addFieldConfigurations = createEffect(() =>
    this.actions$.pipe(
      ofType(addFieldConfigurations),
      switchMap(({ request: fieldConfigurations, callback, error }) =>
        this.functionsService.addFieldConfigurations(fieldConfigurations).pipe(
          tap((x) => callback?.(x)),
          switchMap((fieldConfigurations) =>
            of(
              addFieldConfigurationsResolved({
                addedFieldConfigurations: fieldConfigurations.map(
                  (fc) => new FormFieldConfiguration(fc)
                ),
              })
            )
          ),
          catchError(() => {
            error?.();
            return [];
          })
        )
      )
    )
  );
}
