import { createAction, props } from '@ngrx/store';
import { Client } from '../../models/client';
import { Filter } from 'processdelight-angular-components';

export const getClients = createAction(
  '[Client] Get clients',
  props<{
    newPageSize: number;
    sortedColumn: string;
    sortDirection: string;
    filters: Filter[];
    newPagingCookie?: string;
    callback?: () => void;
  }>()
);

export const getClient = createAction(
  '[Client] Get client',
  props<{ ishtarClientId: string; callback?: (client: Client) => void }>()
);

export const getClientResolved = createAction(
  '[Client] Get client resolved',
  props<{
    client: Client;
  }>()
);

export const getClientsResolved = createAction(
  '[Client] Get clients resolved',
  props<{
    result: Client[];
    pagingCookie: string;
    totalRecordCount: number;
    resetPaging?: boolean;
  }>()
);

export const removeClient = createAction(
  '[Client] Remove client',
  props<{ ishtarClientId: string; callback?: () => void }>()
);

export const removeClientResolved = createAction(
  '[Client] Remove client resolved',
  props<{ ishtarClientId: string }>()
);

export const updateClient = createAction(
  '[Client] Update client',
  props<{ client: Client; callback?: () => void }>()
);

export const updateClientResolved = createAction(
  '[Client] Update client resolved',
  props<{ client: Client }>()
);

export const addClient = createAction(
  '[Client] Add client',
  props<{
    client: Client;
    createTeam?: boolean;
    callback?: (result: Client) => void;
  }>()
);

export const addClientResolved = createAction(
  '[Client] Add client resolved',
  props<{ client: Client }>()
);
