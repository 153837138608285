import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap, tap, mergeMap } from 'rxjs/operators';
import { IshtarCRMService } from '../../services/ishtarcrm.service';
import {
  addContact,
  addContactResolved,
  getContacts,
  getContactsResolved,
  removeContact,
  removeContactResolved,
  updateContact,
  updateContactResolved,
} from './contact.actions';

@Injectable({ providedIn: 'root' })
export class ContactEffects {
  constructor(
    private actions$: Actions,
    private ishtarCRMService: IshtarCRMService
  ) {}
  getContacts = createEffect(() =>
    this.actions$.pipe(
      ofType(getContacts),
      mergeMap(({ ishtarClientId, callback }) =>
        this.ishtarCRMService.getContacts(ishtarClientId).pipe(
          switchMap((contacts) =>
            of(
              getContactsResolved({
                contacts,
              })
            )
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );

  addContact = createEffect(() =>
    this.actions$.pipe(
      ofType(addContact),
      switchMap(({ contact, callback }) =>
        this.ishtarCRMService.addContact(contact).pipe(
          switchMap((contacts) =>
            of(addContactResolved({ contact: contacts[0] }))
          ),
          tap((result) => callback?.(result.contact)),
          catchError((e) => [])
        )
      )
    )
  );

  updateContact = createEffect(() =>
    this.actions$.pipe(
      ofType(updateContact),
      switchMap(({ contact, callback }) =>
        this.ishtarCRMService.updateContact(contact).pipe(
          switchMap((contact) =>
            of(updateContactResolved({ contact: contact[0] }))
          ),
          tap(() => callback?.()),
          catchError((e) => [])
        )
      )
    )
  );

  removeContact = createEffect(() =>
    this.actions$.pipe(
      ofType(removeContact),
      switchMap(({ ishtarContactId, callback }) =>
        this.ishtarCRMService.removeContact(ishtarContactId).pipe(
          switchMap((data) =>
            of(removeContactResolved({ ishtarContactId: data[0] }))
          ),
          tap(() => (callback ? callback() : undefined)),
          catchError((e) => [])
        )
      )
    )
  );
}
