import * as fromClient from './client/client.reducer';
import * as fromContact from './contact/contact.reducer';
import * as fromFieldConfiguration from './field-configuration/field-configuration.reducer';
import * as fromDataProperty from './data-property/data-property.reducer';
import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { ClientEffects } from './client/client.effects';
import { ContactEffects } from './contact/contact.effects';
import { FieldConfigurationEffects } from './field-configuration/field-configuration.effect';
import { DataPropertyEffects } from './data-property/data-property.effect';

export const featureKey = 'coreFeature';

export interface CoreState {
  [fromClient.featureSlice]: fromClient.State;
  [fromContact.featureSlice]: fromContact.State;
  [fromFieldConfiguration.featureSlice]: fromFieldConfiguration.State;
  [fromDataProperty.featureSlice]: fromDataProperty.State;
}

export const reducers = new InjectionToken<ActionReducerMap<CoreState>>(
  featureKey,
  {
    factory: () => ({
      [fromClient.featureSlice]: fromClient.Reducer,
      [fromContact.featureSlice]: fromContact.Reducer,
      [fromFieldConfiguration.featureSlice]: fromFieldConfiguration.Reducer,
      [fromDataProperty.featureSlice]: fromDataProperty.Reducer,
    }),
  }
);
export const effects = [
  ClientEffects,
  ContactEffects,
  FieldConfigurationEffects,
  DataPropertyEffects,
];
