import { Action, createReducer, on } from '@ngrx/store';
import { Client } from 'src/app/models/client';
import { Country } from 'src/app/models/country';
import { AppState } from '../../shared/store/app.reducer';
import {
  getClientsResolved,
  removeClientResolved,
  updateClientResolved,
  addClientResolved,
  getClientResolved,
} from './client.actions';

export const featureSlice = 'client';

export interface State {
  countries: Country[];
  clients: Client[];
  client?: Client;
  pagingCookie: string;
  totalRecordCount: number;
  ishtarClientId?: string;
}
const defaultState: State = {
  countries: [],
  clients: [],
  client: undefined,
  pagingCookie: '',
  totalRecordCount: 0,
  ishtarClientId: undefined,
};

export function Reducer(state: State | undefined, action: Action) {
  return clientReducer(state, action);
}

export const initialState: State = defaultState;

export const clientReducer = createReducer(
  initialState,
  on(
    getClientsResolved,
    (state, { result, pagingCookie, totalRecordCount, resetPaging }) => ({
      ...state,
      clients: resetPaging ? [...result] : [...state.clients, ...result],
      pagingCookie,
      totalRecordCount,
    })
  ),
  on(getClientResolved, (state, { client }) => ({
    ...state,
    client: client,
  })),
  on(removeClientResolved, (state, { ishtarClientId }) => ({
    ...state,
    clients: state.clients.filter((c) => c.ishtarClientId != ishtarClientId),
  })),
  on(updateClientResolved, (state, { client }) => ({
    ...state,
    clients: state.clients.map((c) =>
      c.ishtarClientId == client.ishtarClientId ? client : c
    ),
  })),
  on(addClientResolved, (state, { client }) => ({
    ...state,
    clients: state.clients.concat([client]),
  }))
);

export const clientState = (state: AppState) => state.coreFeature.client;
