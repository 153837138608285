export class Contact {
  ishtarContactId?: string;
  title!: string;
  firstName!: string;
  name?: string;
  email?: string;
  jobTitle?: string;
  language?: { id?: string; name?: string };
  phone?: string;
  phoneCountry?: { id?: string; name?: string };
  client!: { id?: string; name?: string };

  constructor(obj: Partial<Contact>) {
    Object.assign(this, obj);
  }
}
