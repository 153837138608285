import { Action, createReducer, on } from '@ngrx/store';
import { ControlConfiguration } from 'processdelight-angular-components';
import { AppState } from 'src/app/shared/store/app.reducer';
import {
  addDataPropertyResolved,
  getDataPropertiesResolved,
} from './data-property.action';

export const featureSlice = 'dataProperty';

export interface State {
  dataProperties: ControlConfiguration[];
}

const defaultState: State = {
  dataProperties: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return dataPropertyReducer(state, action);
}

export const initialState: State = defaultState;
export const dataPropertyReducer = createReducer(
  initialState,
  on(getDataPropertiesResolved, (state, { result }) => ({
    ...state,
    dataProperties: [...result],
  })),
  on(addDataPropertyResolved, (state, { addedDataProperty }) => ({
    ...state,
    dataProperties: [addedDataProperty].concat([...state.dataProperties]),
  }))
);

export const dataPropertyState = (state: AppState) =>
  state.coreFeature.dataProperty;
