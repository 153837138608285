import { createAction, props } from '@ngrx/store';
import { ControlConfiguration } from 'processdelight-angular-components';

export const getDataProperties = createAction(
  '[DataProperty] Get data properties',
  props<{
    callback?: (dataProperties: ControlConfiguration[]) => void;
  }>()
);

export const getDataPropertiesResolved = createAction(
  '[DataProperty] Get data properties resolved',
  props<{
    result: ControlConfiguration[];
  }>()
);

export const addDataProperty = createAction(
  '[DataProperty] Add DataProperty',
  props<{
    dataProperty: ControlConfiguration;
    callback?: (dataProperty: ControlConfiguration) => void;
    error?: () => void;
  }>()
);

export const addDataPropertyResolved = createAction(
  '[DataProperty] Add DataProperty resolved',
  props<{ addedDataProperty: ControlConfiguration }>()
);
