import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Ishtar365CommunicationService,
  LoaderService,
  Lookup,
  PropertyType,
} from 'processdelight-angular-components';
import { BehaviorSubject, Subject, forkJoin } from 'rxjs';
import { catchError, filter, first, switchMap, tap } from 'rxjs/operators';
import { Country } from '../models/country';
import { GroupUser } from '../models/groupuser';
import { UserLicenseInfo } from '../models/userlicenseinfo';
import { DataPropertyFacade } from '../store/data-property/data-property.facade';
import { IshtarCRMService } from './ishtarcrm.service';
import { addClient } from '../store/client/client.actions';
export const varlicense$ = new BehaviorSubject<UserLicenseInfo | undefined>(
  undefined
);
export const varcountries$ = new BehaviorSubject<Country[] | undefined>(
  undefined
);

export const varusers$ = new BehaviorSubject<GroupUser[] | undefined>(
  undefined
);

export const vargroups$ = new BehaviorSubject<GroupUser[] | undefined>(
  undefined
);

export const varlanguages$ = new BehaviorSubject<any[] | undefined>(undefined);
export const vartranslations$ = new BehaviorSubject<any>({});
export const propertyTypes$ = new BehaviorSubject<PropertyType[]>([]);
export const forms$ = new BehaviorSubject<Lookup[]>([]);
export const propertyRights$ = new BehaviorSubject<Lookup[]>([]);
export const currentApplication$ = new BehaviorSubject<Lookup | undefined>(
  undefined
);

@Injectable({
  providedIn: 'root',
})
export class StartUpService implements OnDestroy {
  destroy$ = new Subject<void>();

  constructor(
    private ishtarCRMService: IshtarCRMService,
    private loaderService: LoaderService,
    private dataPropertyFacade: DataPropertyFacade,
    private router: Router,
    private ishtarCommunicationService: Ishtar365CommunicationService,
    private route: ActivatedRoute
  ) {}

  getLicense() {
    return this.loaderService.startLoading$(
      'Retrieving license information',
      () =>
        this.ishtarCRMService.getLicense().pipe(
          tap((data) => varlicense$.next(data)),
          catchError((error) => {
            this.router.navigate(['/401']);
            throw error;
          })
        )
    );
  }

  getCountries() {
    return this.ishtarCRMService
      .getCountries()
      .pipe(tap((data) => varcountries$.next(data)));
  }

  getUsers() {
    return this.ishtarCRMService
      .getUsers()
      .pipe(tap((data) => varusers$.next(data)));
  }

  getPropertyTypes() {
    return this.ishtarCRMService.getPropertyTypes().pipe(
      tap((data) => {
        const types = data;
        const textType = types.find((t) => t.type === 'Text field');
        if (textType) {
          textType.icon = 'text_fields';
        }
        propertyTypes$.next(data);
      })
    );
  }

  getForms() {
    return this.ishtarCRMService
      .getApplicationForms()
      .pipe(tap((data) => forms$.next(data)));
  }

  getDataProperties() {
    return this.dataPropertyFacade.getDataProperties$();
  }

  getPropertyRights() {
    return this.ishtarCRMService.getPropertyRights().pipe(
      tap((data) => {
        const lookUpData = [...data].map(
          (d) => new Lookup({ id: d.id, name: d.name })
        );
        propertyRights$.next(lookUpData);
      })
    );
  }

  getCurrentApplication() {
    return this.ishtarCRMService
      .getCurrentApplication()
      .pipe(tap((data) => currentApplication$.next(data)));
  }

  getGroups() {
    return this.ishtarCRMService
      .getGroups()
      .pipe(tap((data) => vargroups$.next(data)));
  }

  getLanguages() {
    return this.ishtarCRMService
      .getLanguages()
      .pipe(tap((data) => varlanguages$.next(data)));
  }

  getTranslations() {
    return this.ishtarCRMService
      .getTranslations()
      .pipe(tap((data) => vartranslations$.next(data)));
  }

  boot() {
    return this.getLicense().pipe(
      filter((license) => !!license),
      first(),
      switchMap((license) => {
        if (!license?.licenses.some((l) => l.productName == 'Ishtar.CRM')) {
          this.router.navigate(['/401']);
          throw new Error('No license for Ishtar.CRM');
        }

        return this.loaderService.startLoading$('Starting application...', () =>
          forkJoin([
            this.getTranslations().pipe(
              filter((t) => !!Object.keys(t).length),
              first()
            ),
            this.getCountries(),
            this.getUsers(),
            this.getPropertyTypes(),
            this.getForms(),
            this.getDataProperties(),
            this.getPropertyRights(),
            this.getCurrentApplication(),
            this.getGroups(),
            this.getLanguages(),
          ]).pipe(
            tap(() => {
              try {
                this.ishtarCommunicationService.init();
                this.ishtarCommunicationService.registerRedirectAction(
                  'OpenClient',
                  (id?: string, data?: unknown) => {
                    const segments = this.route.snapshot.url.map((s) => s.path);
                    if (segments[0] !== 'clients')
                      this.router.navigate(['clients'], {
                        queryParams: { clientId: id },
                      });
                  }
                );
              } catch {
                // Not in Ishtar365
              }
            })
          )
        );
      })
    );
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
