import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ControlConfiguration } from 'processdelight-angular-components';
import { map, Subject } from 'rxjs';
import { AppState } from 'src/app/shared/store/app.reducer';
import { addDataProperty, getDataProperties } from './data-property.action';
import { dataPropertyState } from './data-property.reducer';

@Injectable({
  providedIn: 'root',
})
export class DataPropertyFacade {
  dataProperties$ = this._store.pipe(
    select(dataPropertyState),
    map((state) => state.dataProperties)
  );
  constructor(private _store: Store<AppState>) {}

  getDataProperties$() {
    const subject = new Subject<ControlConfiguration[]>();
    this._store.dispatch(
      getDataProperties({
        callback: (x) => {
          subject.next(x);
          subject.complete();
        },
      })
    );

    return subject.asObservable();
  }

  addDataProperty(
    dataProperty: ControlConfiguration,
    callback?: (dataProperty: ControlConfiguration) => void,
    error?: () => void
  ) {
    const subject = new Subject<ControlConfiguration>();

    this._store.dispatch(
      addDataProperty({
        dataProperty,
        callback: (x) => {
          if (callback) {
            callback(x);
          }
          subject.next(x);
          subject.complete();
        },
        error,
      })
    );

    return subject.asObservable();
  }
}
